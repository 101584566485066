<template>
  <div
    :class="[
      getCompactSideBarToggleProperties.isSideNavOpen ? 'sidenav-open' : '',
      getcompactLeftSideBarBgColor
    ]"
    class="app-admin-wrap layout-sidebar-compact   clearfix"
  >
    <compactSidebar></compactSidebar>
    <!-- end-leftsidebar -->
    <div class="main-content-wrap d-flex flex-column">
      <compactTopbar></compactTopbar>
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
      <appFooter></appFooter>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import compactTopbar from './compactTopbar.vue';
import compactSidebar from './compactSidebar.vue';
import appFooter from '../common/footer.vue';

export default {
  components: {
    compactTopbar,
    compactSidebar,
    appFooter,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'getCompactSideBarToggleProperties',
      'getcompactLeftSideBarBgColor',
    ]),
  },
};
</script>
